import Axios from 'axios';

const M = require('materialize-css');

export const toast = obj => {
    M.toast({ html: obj.payload.msg, classes: obj.payload.class })
}


export const logout = err => {
    try {
        if (typeof err === 'string')
            return toast({ payload: { msg: err, class: 'red white-text' } });

        if (!Array.isArray(err) && typeof err === 'object') {
            if (!Axios.isCancel(err)) {
                if (err.response) {
                    if (err.response.status === 401) {
                        localStorage.clear();
                        window.location.replace('/');
                        toast({ payload: { msg: 'Not Authenticated', class: 'orange white-text' } })
                    }
                    else
                        toast(err.response.data)
                }
            }
        }
    } catch (err) {
        return toast({ payload: { msg: 'An Unknown Error Occurred.', class: 'red white-text' } })
    }
}