import { Link, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import M from 'materialize-css';
import { useLayoutEffect } from "react";
import WholesalerAccounts from "./WholesalerAccounts";

const SettingsPage = props => {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        M.Tabs.init(document.querySelectorAll('.tabs'));
        return () => document.querySelectorAll('.tabs').forEach(el => M.Tabs.getInstance(el)?.destroy());
    }, [pathname])


    return (
        <div>
            <div className="row" style={{ marginTop: '40px' }}>
                <div className="col s12">
                    <ul className="tabs">
                        <li className="tab col" onClick={() => navigate('/settings/changepassword')}><Link to="/settings/changepassword">Change Password</Link></li>
                        <li className="tab col" onClick={() => navigate('/settings/wholesaleraccounts')}><Link to="/settings/wholesaleraccounts">Wholesaler Accounts</Link></li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <Routes>
                    <Route path="/changepassword" element={<ChangePassword />} />
                    <Route path="/wholesaleraccounts" element={<WholesalerAccounts />} />
                </Routes>
            </div>
        </div>
    )
}

export default SettingsPage;