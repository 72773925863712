import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { logout } from "../../func";
import 'materialize-css';
import moment from "moment/moment";
import FileUploader from '../../utilities/fileUploader';
const pageId = '_' + crypto.randomUUID();

const MedDetailPage = props => {

    const { medId } = useParams();
    const [progress, setProgress] = useState(null);
    const [med, setMed] = useState(null);
    const [uploading, setUploading] = useState(false);

    const ref = useRef({
        abortController: new AbortController(),
    })

    const getMedDetail = () => {

        Axios.get('/api/v1/med/read/one/by/id', {
            params: {
                ...JSON.parse(localStorage.getItem('auth_data')),
                medId
            }
        })
            .then(result => setMed(result.data))
            .catch(logout)
    }

    useEffect(() => {
        getMedDetail();

        return () => ref.current.abortController?.abort();
    }, [])

    const uploadAttachments = files => {
        setUploading(true);

        const fileUploader = new FileUploader(
            files,
            '/api/v1/file/upload/medattachment',
            { medId: med.med.id }
        );
        fileUploader.setProgressCallback(progress => {
            setProgress({ ...progress });
        });
        fileUploader.setOnUploadFinish(() => {
            setProgress(null);
            getMedDetail();
            setUploading(false);
        });
        return fileUploader.upload();
    }

    const onDragOver = e => {
        e.stopPropagation();
        e.preventDefault();
    }

    const onDrop = e => {
        e.preventDefault();

        const { files } = e.dataTransfer;

        const arr = [];

        for (let i = 0; i < files.length; i++)
            arr.push(files[i]);

        uploadAttachments(files);
    }

    const downloadFile = (e, attachmentId) => {
        e.preventDefault();
        ref.current.abortController = new AbortController();

        Axios.get('/api/v1/file/download/medattachment', {
            params: {
                ...JSON.parse(localStorage.getItem('auth_data')),
                attachmentId
            },
            signal: ref.current.abortController?.signal
        })
            .then(result => {
                const { filename, buffer } = result.data;
                const uint8Array = Uint8Array.from(buffer.data);
                const blob = new Blob([uint8Array]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.hidden = true;
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(logout);
    }

    return (
        <div>
            <div className="row">
                <h5 style={{ textDecoration: 'underline' }}>Serial Number: {med?.med.serialNumber}</h5>
            </div>
            <div className="row">
                <div className="col s12">
                    <table className="col s12">
                        {med ?
                            <tbody>
                                <Tr label="NDC" data={med.redbook.ndc} />
                                <Tr label="Serial #" data={med.med.serialNumber} />
                                <Tr label="Med Name" data={med.redbookDetail.medName} />
                                <Tr label="Manufacturer" data={med.redbookDetail.manufacturerName} />
                                <Tr label="Lot #" data={med.med.lotNumber} />
                                <Tr label="Expiration" data={med.med.expirationDate ? moment(med.med.expirationDate).format('MM/DD/YYYY') : ''} />
                                <Tr label="Record Creation Date" data={moment(med.redbookDetail.dateCreated).format('MM/DD/YYYY')} />
                                <Tr label="Record Created By" data={`${med.createdBy.firstName || ''}${med.createdBy.lastName ? ' ' + med.createdBy.lastName : ''}`} />
                                <Tr label="Organization" data={med.customer.name} />
                                <Tr label="Location" data={med.location.name} />
                                <tr>
                                    <td style={{ padding: '2px' }}><b>Attachments</b></td>
                                    <td>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>Date Added</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {med?.attachments ?
                                                    med.attachments.map(a => (
                                                        <tr key={a.id}>
                                                            <td style={{ padding: '2px' }}><a href="/" onClick={e => downloadFile(e, a.id)}>{a.filename}</a></td>
                                                            <td style={{ padding: '2px' }}>{a.contentType}</td>
                                                            <td style={{ padding: '2px' }}>{moment(a.timeStamp).format('MM/DD/YY')}</td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <tr><td style={{ padding: '2px' }}>No Attachments</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                            : null}
                    </table>
                    {uploading ?
                        <div
                            className=" col s12 blue-text"
                            style={{
                                marginTop: '20px',
                                padding: '8px',
                                border: '2px #aaa dashed',
                                borderRadius: '5px',
                                minHeight: '120px',
                                fontWeight: '500'
                            }}
                        >
                            {progress && Object.keys(progress).map(key => {
                                return (
                                    <div key={key}>
                                        <p>{key}</p>
                                        <div className="progress">
                                            <div className="determinate" style={{ width: (progress[key].currentPacket / progress[key].totalPackets * 100) + '%' }} />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div
                            id={`file-upload-${pageId}`}
                            className="col s12"
                            style={{
                                marginTop: '20px',
                                padding: '8px',
                                border: '2px #aaa dashed',
                                borderRadius: '5px',
                                minHeight: '120px'
                            }}
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                        >
                            <h6>Drag and Drop Attachments here</h6>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MedDetailPage;

const Tr = props => <tr>
    <td style={{ padding: '2px' }}><b>{props.label}</b></td>
    <td style={{ padding: '2px' }}>{props.data}</td>
</tr>