import Axios from "axios";
import { useEffect, useRef, useState, Fragment, useLayoutEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { logout, toast } from "../../func";
import dataMatrixCodec from "../../utilities/dataMatrixCodec";
import styled from '@emotion/styled';
import moment from "moment";
import { BsThreeDots } from 'react-icons/bs'
import { IoMdCheckmarkCircleOutline, IoMdReturnRight } from 'react-icons/io'
import M from 'materialize-css';

const { NODE_ENV } = process.env;


const OrderScan = props => {
    const { documentId } = useParams();

    const ref = useRef({
        abortController: new AbortController(),
        sgtinTimer: null
    })
    const getAuthData = () => JSON.parse(localStorage.getItem('auth_data'));

    const [loading, setLoading] = useState(false);
    const [meds, setMeds] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [sgtin, setSgtin] = useState('');
    const [groupSeparatorArray, setGroupSeparatorArray] = useState([])
    const [selectedMed, setSelectedMed] = useState(null);

    const loadMeds = useCallback(() => {
        setLoading(true);
        Axios.get('/api/v1/order/read/all/meds/by/documentid', {
            params: {
                ...getAuthData(),
                documentId
            },
            signal: ref.current.abortController.signal
        })
            .then(result => setMeds(result.data))
            .catch(logout)
            .finally(() => setLoading(false));
    }, [documentId])

    const receiveMed = () => {
        setLoading(true)

        Axios.post('/api/v1/med/update/receive/many/by/serialnumber', {
            ...getAuthData(),
            serialNumber: sgtin,
            documentId
        })
            .then(result => {
                toast(result.data);
                loadMeds();
                setSgtin('');
                document.querySelector('#sgtin')?.focus();
            })
            .catch(logout)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const abortController = ref.current.abortController;
        loadMeds();
        return () => NODE_ENV === 'production' ? abortController.abort() : undefined;
    }, [loadMeds, ref.current.abortController])

    useLayoutEffect(() => {
        M.Modal.init(document.querySelector('#med-detail-modal'));
        return () => document.querySelectorAll('.modal').forEach(el => { if (el) { M.Modal.getInstance(el).destroy() } })
    }, [])

    const onChange = ({ target: { value } }) => setSgtin(value);

    const onKeyDown = e => {
        const { keyCode, ctrlKey } = e;

        if (keyCode === 221 && ctrlKey) {
            const arr = groupSeparatorArray;
            arr.push(sgtin?.length);
            setGroupSeparatorArray(arr);
        }

        if (keyCode === 13) {
            receiveMed();
        }
    }

    const parseBarcode = barcode => {
        Object.keys(barcode).forEach(key => {
            switch (key) {
                case dataMatrixCodec.SERIAL_NUMBER:
                    setSgtin(barcode[key]);
                    break;
                default:
                    console.log(`Unknown property: ${key}, value: ${barcode[key]}`)
            }

            document.querySelector('#sgtin')?.focus();
        })
    }

    useEffect(() => {
        if (ref.current.sgtinTimer)
            clearTimeout(ref.current.sgtinTimer);

        ref.current.sgtinTimer = setTimeout(() => {
            if (sgtin && sgtin.length > 20 && sgtin.substring(0, 3).toLocaleLowerCase() !== 'orx') {
                try {
                    let temp = '';
                    groupSeparatorArray?.forEach((gs, index) => temp = [sgtin.slice(0, gs + index), ' ', sgtin.slice(gs + index)].join(''));
                    setSgtin(temp);
                    setGroupSeparatorArray([]);

                    const barcode = dataMatrixCodec.decode(sgtin);
                    parseBarcode(barcode);
                } catch (err) { console.log(err) }
            }
        }, 250)
    }, [sgtin, groupSeparatorArray])

    useEffect(() => {
        const tableData = [];
        const drillDown = (arr, hierarchy = []) => {
            arr.forEach(item => {
                const newhierarchy = [...hierarchy]; // Create a copy of the hierarchy array
                if (item.children) {
                    const hierarchyData = JSON.parse(JSON.stringify(item));
                    delete hierarchyData.children;
        
                    newhierarchy.push(hierarchyData); // Push to the new array
                    drillDown(item.children, newhierarchy); // Pass the new array to the recursive call
                }
                else {
                    const group = tableData.find(group => group.epcisMedVocabularyId === item.epcisMedVocabularyId);
                    group ?
                        group.meds.push(item) :
                        tableData.push({
                            epcisMedVocabularyId: item.epcisMedVocabularyId,
                            hierarchy: newhierarchy, // Use the new array here
                            meds: [item]
                        });
                }
            })
        }

        if (meds) {
            drillDown(meds);
            setTableData(tableData);
        }
    }, [meds])

    const Table = styled.table`
        tr:has(+ tr.table-drawer[data-open='open']){
            border: 2px solid black;
        }


        tr.table-drawer{
            display: none;
            
            &[data-open='open']{
                display: table-row;
                border-bottom: 2px solid black;
            
                &>td{
                    border-left: 2px solid black;
                    border-right: 2px solid black;
                }
            }

        }

    `

    const toggleTableDrawer = (id) => {
        const drawer = document.getElementById(`table-drawer-${id}`);

        if (drawer) {
            const status = drawer.getAttribute('data-open');
            drawer.setAttribute('data-open', status === 'open' ? 'closed' : 'open');
        }
    }

    const showModal = (med, hierarchy) => {
        setSelectedMed({ ...med, hierarchy });
        const el = document.querySelector('#med-detail-modal');
        const modal = M.Modal.getInstance(el);
        modal.open();
    }
    return (
        <div>
            <div className="row">
                <div className="input-field col s12 m4">
                    <input id="sgtin" type="text" value={sgtin} onChange={onChange} onKeyDown={onKeyDown} tabIndex="1" disabled={loading} />
                    <label htmlFor="sgtin">Serial #</label>
                </div>
            </div>
            <div className="row">
                <Table>
                    <thead>
                        <tr>
                            <th style={{ padding: '4px' }}>Name</th>
                            <th style={{ padding: '4px' }}>NDC</th>
                            <th style={{ padding: '4px' }}>Type</th>
                            <th style={{ padding: '4px' }}>Description</th>
                            <th style={{ padding: '4px', textAlign: 'right' }}>Received</th>
                            <th style={{ padding: '4px', textAlign: 'right' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map(medGroup => {
                            const med = medGroup.meds[0];
                            const receivedCount = medGroup.meds?.filter(med => med.receivedById).length;
                            const totalCount = medGroup.meds?.length;
                            const isReceived = receivedCount === totalCount
                            return (
                                <Fragment key={medGroup.epcisMedVocabularyId}>
                                    <tr style={{ cursor: 'pointer' }} className={`bold`} onClick={() => toggleTableDrawer(medGroup.epcisMedVocabularyId)}>
                                        <td style={{ padding: '8px', borderRadius: '0px' }}>{med?.epcisMedVocabulary?.regulatedProductName}</td>
                                        <td style={{ padding: '8px', borderRadius: '0px' }}>{med?.epcisMedVocabulary?.additionalTradeItemId}</td>
                                        <td style={{ padding: '8px', borderRadius: '0px' }}>{med?.epcisMedVocabulary?.dosageFormType === 'Unknown' ? '' : med?.epcisMedVocabulary?.dosageFormType || ''}</td>
                                        <td style={{ padding: '8px', borderRadius: '0px' }}>{med?.epcisMedVocabulary?.strengthDescription}</td>
                                        <td style={{ padding: '0px', borderRadius: '0px', textAlign: 'right' }}>{isReceived ? <IoMdCheckmarkCircleOutline className="green-text" style={{ fontSize: '24px', position: 'relative', top: '3px', right: '12px' }} /> : receivedCount}</td>
                                        <td style={{ padding: '8px', borderRadius: '0px', textAlign: 'right' }}>{totalCount}</td>
                                    </tr>
                                    <tr id={`table-drawer-${medGroup.epcisMedVocabularyId}`} className="table-drawer" data-open="closed">
                                        <td style={{ padding: '2px', borderBottom: 'none' }} colSpan={6}>
                                            <table style={{ margin: '0px', padding: '0px' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '0px' }}></th>
                                                        <th style={{ padding: '0px' }}>SGTIN</th>
                                                        <th style={{ padding: '0px' }}>Date Received</th>
                                                        <th style={{ padding: '0px' }}>Received By</th>
                                                        <th style={{ padding: '0px' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {medGroup.meds?.map((med, index) => (
                                                        <tr key={med.sgtin} style={index === medGroup.meds.length - 1 ? { border: 'none' } : {}}>
                                                            <td style={{ padding: '2px' }}></td>
                                                            <td style={{ padding: '2px' }}>{med.sgtin}</td>
                                                            <td style={{ padding: '2px' }}>{med.datetimeReceived ? moment(med.datetimeReceived).format('MM/DD/YY') : ''}</td>
                                                            <td style={{ padding: '2px' }}>{med.receivedById ? `${med.receivedBy.firstName} ${med.receivedBy.lastName}` : ''}</td>
                                                            <td style={{ padding: '2px' }}><BsThreeDots style={{ cursor: 'pointer' }} onClick={() => showModal(med, medGroup.hierarchy)} /></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div id="med-detail-modal" className="modal">
                {selectedMed ?
                    <div className="modal-content">
                        <div className="row">
                            <h5 className="bolder"><i>{selectedMed.sgtin}</i></h5>
                        </div>
                        <div className="divider" />
                        <div className="row" style={{ fontSize: '1.25rem', padding: '2rem', marginBottom: '60px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>Med Name:</b>
                                    <span>{`${selectedMed.epcisMedVocabulary.regulatedProductName} ${selectedMed.epcisMedVocabulary.strengthDescription ? ` - ${selectedMed.epcisMedVocabulary.strengthDescription}` : ''}`}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>Med Serial Prefix:</b>
                                    <span>{selectedMed.epcisMedVocabulary.sgtin.replace('.*', '')}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>Item Serial Number:</b>
                                    <span>{selectedMed.sgtin.replace(selectedMed.epcisMedVocabulary.sgtin.replace('*', ''), '').replace('.', '')}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>NDC:</b>
                                    <span>{selectedMed.epcisMedVocabulary.additionalTradeItemId || ''}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>Dosage Form Type:</b>
                                    <span>{selectedMed.epcisMedVocabulary.dosageFormType === 'Unknown' ? '' : selectedMed.epcisMedVocabulary.dosageFormType || ''}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>Date Received:</b>
                                    <span>{selectedMed.datetimeReceived ? moment(selectedMed.datetimeReceived).format('MM/DD/YY') : ''}</span>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                    <b>Received By:</b>
                                    <span>{selectedMed.receivedById ? selectedMed.receivedBy.firstName + ' ' + selectedMed.receivedBy.lastName : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="row" style={{ marginBottom: '5px' }}>
                                <h5 className="bold">Packaging Detail</h5>
                            </div>
                            <div className="divider" style={{ marginBottom: '15px' }} />
                            <div className="row" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignContent: 'start', fontSize: '1.1em' }}>
                                {selectedMed.hierarchy && selectedMed.hierarchy.length ?
                                    <>
                                        {selectedMed.hierarchy?.map((parentMed, index) => (
                                            <div key={parentMed.sgtin} style={{ paddingLeft: 2 * index + 'rem', display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                                {index !== 0 ? <IoMdReturnRight /> : null}
                                                <span className="bold">{parentMed.epcisMedVocabulary.regulatedProductName} - {parentMed.sgtin}</span>
                                            </div>
                                        ))
                                        }
                                        <div style={{ paddingLeft: 2 * selectedMed.hierarchy.length + 'rem', display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '12px' }}>
                                            <IoMdReturnRight />
                                            <span className="bold"><i>This Med</i></span>
                                        </div>
                                    </>
                                    :
                                    <p>This med was not part of a larger package</p>}
                            </div>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default OrderScan;