import React, { useEffect, useState } from 'react';
import { useLocation, Link, matchPath } from 'react-router-dom';
import medTraxLogo from '../assets/medtrax_logo.png';

const Banner = props => {

    const { pathname } = useLocation();
    const [page, setPage] = useState('Home');

    useEffect(() => {
        if (pathname === '/inputmeds')
            setPage('Create A Pedigree');
        else if (pathname === '/searchmeds')
            setPage('Your Pedigrees');
        else if (matchPath('/meddetail/:medId', pathname))
            setPage('Pedigree Detail');
        else if (pathname === '/sendapedigree')
            setPage('Send A Pedigree');
        else if (matchPath('/sendapedigree/:serialNumber', pathname))
            setPage(pathname.split('/sendapedigree/')[1])
        else if (pathname === '/reportanincident')
            setPage('Report An Incident');
        else if (pathname === '/settings')
            setPage('User Settings');
        else if (pathname === '/settings/changepassword')
            setPage('Change Password')
        else if (pathname === '/settings/wholesaleraccounts')
            setPage('Wholesaler Accounts');
        else if (pathname === '/orders')
            setPage('Search Overview')
        else if (matchPath('/orders/receive/:id', pathname))
            setPage('Recieve Order')
        else if (pathname === '/orderupload')
            setPage('Order Upload')
        else if (pathname === '/demo/epcis/file/generator')
            setPage('Demo EPCIS File Generator')
        else
            setPage('Welcome');
    }, [pathname])

    return (
        <div id="hulk-banner" className="red" style={{ height: '128px', maxWidth: '100%', zIndex: -1 }}>
            <span className="center white-text">{page}</span>
            <div>
                <Link to="/">
                    <img className="responsive-img right hide-on-med-and-down" src={medTraxLogo} alt="Outdate Rx Logo" style={{ maxHeight: '90px', position: 'relative', bottom: '40px', right: '24px' }} />
                </Link>
            </div>
        </div>
    )
}

export default Banner;