import { useEffect, useLayoutEffect, useState } from "react";
import M from 'materialize-css';

const ReportAnIncident = props => {

    const initValues = () => ({
        typeOfReport: 'initial',
        incidentNumber: '',
        initialNotificationDate: '',
        productFoundDate: '',
        classification: 'counterfeit',
        nameOfProduct: '',
        primaryIngredients: '',
        use: 'human use',
        description: 'finished',
        strength: '',
        dosageForm: 'tablet',
        quantity: '',
        ndc: '',
        serialNumber: '',
        lotNumber: '',
        expiration: '',
        eventNotes: '',
        terminationNotes: '',
        bdpr: false,
        medWatch3500: false,
        none: false,
        far: false,
        medWatch3500a: false,
        other: false,
        otherSpecify: '',
        pharmacyName: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        companyCategory: 'dispenser',
        uniqueFacilityIdentifier: '',
        contactName: '',
        contactPhone: '',
        contactEmail: ''
    })

    useLayoutEffect(() => {
        M.FormSelect.init(document.querySelectorAll('select'));
    }, [])

    const [values, setValues] = useState(initValues())

    const onChange = ({ target: { id, value, type } }) => setValues(p => ({ ...p, [id]: type === 'checkbox' ? !p[id] : value }))

    useEffect(() => {
        if (values.none)
            setValues(p => ({ ...p, bdpr: false, far: false, medWatch3500: false, medWatch3500a: false, other: false, otherSpecify: '' }))
    }, [values.none])


    return (
        <div>
            <div className="row">
                <h5 className="red-text">TO DO: MED LOOKUP</h5>
            </div>
            <div className="row">
                <div className="input-field col s12 m4 l2">
                    <select id="typeOfReport" value={values.typeOfReport} onChange={onChange}>
                        <option value="initial">Initial Notification</option>
                        <option value="followup">Follow-Up Notification</option>
                        <option value="terminate">Request for Termination</option>
                    </select>
                    <label htmlfor="typeOfReport">Type of Report</label>
                </div>
                <div className="input-field col s12 m4 l2">
                    <input id="incidentNumber" type="text" value={values.incidentNumber} onChange={onChange} disabled={values.typeOfReport === 'initial'} />
                    <label htmlfor="incidentNumber">Incident Number</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m4 l3">
                    <input id="initialNotificationDate" type="date" value={values.initialNotificationDate} onChange={onChange} />
                    <label htmlfor="initialNotificationDate">Date of Initial Notification</label>
                </div>
                <div className="input-field col s12 m4 l3">
                    <input id="productFoundDate" type="date" value={values.productFoundDate} onChange={onChange} />
                    <label htmlfor="productFoundDate">Date Product Determined Illegitimate</label>
                </div>
                <div className="input-field col s12 m4 l3">
                    <select id="classification" value={values.classification} onChange={onChange}>
                        <option value="counterfeit">Counterfeit</option>
                        <option value="diverted">Diverted</option>
                        <option value="stolen">Stolen</option>
                        <option value="intentional adulteration">Intentional Adulteration</option>
                        <option value="unfit for distribution">Unfit for Distribution</option>
                        <option value="fraudulent transaction">Fraudulent Transaction</option>
                    </select>
                    <label htmlfor="classification">Classification of Notification</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 l9">
                    <input id="nameOfProduct" type="text" value={values.nameOfProduct} onChange={onChange} />
                    <label htmlfor="nameOfProduct">Name of Product as It Appears on Label</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <input id="primaryIngredients" type="text" value={values.primaryIngredients} onChange={onChange} />
                    <label htmlfor="primaryIngredients">Primary Ingredients</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s6">
                    <select id="use" value={values.use} onChange={onChange}>
                        <option value="human use">Human Use</option>
                        <option value="other">Other</option>
                    </select>
                    <label htmlfor="use">Drug Use</label>
                </div>
                <div className="input-field col s6">
                    <select id="description" value={values.description} onChange={onChange}>
                        <option value="finished prescription drug">Finished Prescription Drug</option>
                        <option value="vaccine">Vaccine</option>
                        <option value="plasma derivative">Plasma Derivative (Coagulation Factors, Immunoglobulins, Albumin)</option>
                        <option value="allergenic">Allergenic (Standardized and Non-Standardized)</option>
                        <option value="multiple">Multiple</option>
                    </select>
                    <label htmlFor="description">Drug Description</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m3">
                    <input id="strength" type="text" value={values.strength} onChange={onChange} />
                    <label htmlfor="strength">Strength of Drug</label>
                </div>
                <div className="input-field col s12 m3">
                    <select id="dosageForm" value={values.dosageForm} onChange={onChange}>
                        <option value="tablet">Tablet</option>
                        <option value="capsule">Capsule</option>
                        <option value="sublingual">Sublingual</option>
                        <option value="aerosol">Aerosol</option>
                        <option value="oral liquid">Oral Liquid</option>
                        <option value="injectable">Injectable</option>
                        <option value="topical">Topical</option>
                        <option value="suppository">Suppository</option>
                        <option value="other">Other</option>
                        <option value="multiple">Multiple</option>
                    </select>
                    <label htmlFor="dosageForm">Dosage Form</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m6">
                    <input id="quantity" type="text" value={values.quantity} onChange={onChange} />
                    <label htmlfor="quantity">Quantity of Drug (Number and Unit)</label>
                </div>
                <div className="input-field col s12 m3">
                    <input id="ndc" type="text" value={values.ndc} onChange={onChange} />
                    <label htmlfor="ndc">NDC (if applicable)</label>
                </div>
                <div className="input-field col s12 m3">
                    <input id="serialNumber" type="text" value={values.serialNumber} onChange={onChange} />
                    <label htmlfor="serialNumber">Serial Number (if applicable)</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <input id="lotNumber" type="text" value={values.lotNumber} onChange={onChange} />
                    <label htmlfor="lotNumber">Lot Number(s)</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <input id="expiration" type="text" value={values.expiration} onChange={onChange} />
                    <label htmlfor="expiration">Expiration(s)</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <textarea id="eventNotes" className="materialize-textarea" value={values.eventNotes} onChange={onChange} />
                    <label htmlfor="eventNotes">For Notification: Description of Event/Issue</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12">
                    <textarea id="terminationNotes" className="materialize-textarea" value={values.terminationNotes} onChange={onChange} />
                    <label htmlfor="terminationNotes">For Request For Termination of Notification: Description of why notificaiton is no longer necessary</label>
                </div>
            </div>
            <div className="divider" />
            <div className="row">
                <h6>If you have submitted information to FDA through an alternative mechanism, check all that apply.</h6>
                <p>
                    <label>
                        <input id="bdpr" type="checkbox" class="filled-in" checked={values.bdpr ? 'checked' : ''} onChange={onChange} />
                        <span>BPDR</span>
                    </label>
                </p>
                <p>
                    <label>
                        <input id="far" type="checkbox" class="filled-in" checked={values.far ? 'checked' : ''} onChange={onChange} />
                        <span>FAR</span>
                    </label>
                </p>
                <p>
                    <label>
                        <input id="medWatch3500" type="checkbox" class="filled-in" checked={values.medWatch3500 ? 'checked' : ''} onChange={onChange} />
                        <span>MedWatch 3500</span>
                    </label>
                </p>
                <p>
                    <label>
                        <input id="medWatch3500a" type="checkbox" class="filled-in" checked={values.medWatch3500a ? 'checked' : ''} onChange={onChange} />
                        <span>MedWatch 3500A</span>
                    </label>
                </p>
                <div style={{ display: 'flex' }}>
                    <p style={{marginRight: '20px'}}>
                        <label>
                            <input id="other" type="checkbox" class="filled-in" checked={values.other ? 'checked' : ''} onChange={onChange} />
                            <span>Other</span>
                        </label>
                    </p>
                    <div className="input-field col s12" style={{ position: 'relative', bottom: '18px' }}>
                        <input id="otherSpecify" type="text" value={values.otherSpecify} onChange={onChange} disabled={!values.other} />
                        <label htmlfor="otherSpecify">Specify</label>
                    </div>
                </div>
                <p>
                    <label>
                        <input id="none" type="checkbox" class="filled-in" checked={values.none ? 'checked' : ''} onChange={onChange} />
                        <span>None</span>
                    </label>
                </p>
            </div>
        </div>
    )
}

export default ReportAnIncident;