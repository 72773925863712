import { Route, Routes } from "react-router-dom";
import OrderOverview from "./orderOverview";
import OrderScan from "./orderScan";

const orderRouter = props => (
    <Routes>
        <Route path="/" element={<OrderOverview />} />
        <Route path="/receive/:documentId" element={<OrderScan />} />
    </Routes>
);


export default orderRouter;