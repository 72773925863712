import Axios from "axios";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import M from 'materialize-css';
import { logout, toast } from '../../func';
import dataMatrixCodec from "../../utilities/dataMatrixCodec";
import moment from "moment/moment";

const MedInput = props => {
    const {
        locationId,
        getMedList
    } = props;

    const initValues = () => ({
        serialNumber: '',
        gtin: '',
        ndc: '',
        lotNumber: '',
        expirationDate: '',
    })

    const ref = useRef({
        ndcAbortController: new AbortController(),
        firstSeven: '',
        serialNumberEffectTimer: null,
        serialNumberKeyDownTimer: null
    });

    const [saving, setSaving] = useState(false);
    const [redbookList, setRedbookList] = useState(null);
    const [values, setValues] = useState(initValues())
    const [firstSeven, setFirstSeven] = useState('');
    const [groupSeparatorArray, setGroupSeparatorArray] = useState([]);

    useLayoutEffect(() => {
        document.querySelector('#serialNumber')?.focus();
    }, [])

    useEffect(() => {
        ref.current.firstSeven = firstSeven;
    }, [firstSeven])

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    const saveMed = e => {
        e?.preventDefault();

        setSaving(true);
        Axios.post('/api/v1/med/create/one', {
            ...JSON.parse(localStorage.getItem('auth_data')),
            ...values,
            medName: document.querySelector('#medName').value,
            manufacturerName: document.querySelector('#manufacturerName').value,
            locationId
        })
            .then(result => {
                getMedList();
                toast(result.data)
                setValues(initValues());
                document.querySelector('#medName').value = '';
                document.querySelector('#manufacturerName').value = '';
                document.querySelector('#serialNumber')?.focus();
            })
            .catch(logout)
            .finally(() => setSaving(false))
    }

    useEffect(() => {
        if (values.ndc && values.ndc.length >= 5 && values.ndc.substring(0, 5) !== ref.current.firstSeven) {
            setFirstSeven(values.ndc.substring(0, 5))
            ref.current.ndcAbortController?.abort();
            ref.current.ndcAbortController = new AbortController();

            Axios.get('/api/v1/redbook/read/search/by/ndc', {
                params: {
                    ...JSON.parse(localStorage.getItem('auth_data')),
                    ndc: values.ndc
                },
                signal: ref.current.ndcAbortController.signal
            })
                .then(result => setRedbookList(result.data))
                .catch(err => console.log(err))
        }
    }, [values.ndc])

    useEffect(() => {
        const { gtin } = values;
        let ndc = '';

        if (gtin && gtin.length > 3) {
            if (gtin.length < 14)
                ndc = gtin.substring(3, gtin.length);
            else
                ndc = gtin.substring(3, gtin.length - 1);
        }

        setValues(p => ({ ...p, ndc }))

    }, [values.gtin])

    useLayoutEffect(() => {
        const medNameList = redbookList?.filter(row => row.ndc === values.ndc)
            .reduce((obj, row) => ({ ...obj, [row.medName]: null }), {}) || {}

        const manufacturerList = redbookList?.filter(row => row.ndc === values.ndc)
            .reduce((obj, row) => ({ ...obj, [row.manufacturerName]: null }), {}) || {}

        M.Autocomplete.init(document.querySelector('#medName'), { data: medNameList });
        M.Autocomplete.init(document.querySelector('#manufacturerName'), { data: manufacturerList })

        return () => {
            document.querySelectorAll('.autocomplete').forEach(el => {
                M.Autocomplete.getInstance(el)?.destroy()
            })
        }
    }, [redbookList, values])

    const serialNumberKeyDown = e => {
        if (e.keyCode === 221 && e.ctrlKey) {
            const arr = groupSeparatorArray;
            arr.push(values.serialNumber.length);
            setGroupSeparatorArray(arr);
        }
    }

    const parseBarcode = barcode => {
        const values = {};

        Object.keys(barcode).forEach(key => {
            switch (key) {
                case dataMatrixCodec.BATCH_OR_LOT_NUMBER:
                    values.lotNumber = barcode[key];
                    break;
                case dataMatrixCodec.EXPIRATION_DATE_YYMMDD:
                    values.expirationDate = moment(barcode[key], 'YYMMDD').format('YYYY-MM-DD');
                    break;
                case dataMatrixCodec.GLOBAL_TRADE_ITEM_NUMBER:
                    values.gtin = barcode[key];
                    values.ndc = barcode[key].substring(3, barcode[key].length - 1);
                    break;
                case dataMatrixCodec.SERIAL_NUMBER:
                    values.serialNumber = barcode[key];
                default:
                    console.log(`Unknown property: ${key}, value: ${barcode[key]}`)
            }

            setValues(p => ({ ...p, ...values }))
            document.querySelector('#medName').focus();
        })
    }

    useEffect(() => {
        if (ref.serialNumberEffectTimer)
            clearTimeout(ref.serialNumberEffectTimer);

        ref.serialNumberEffectTimer = setTimeout(() => {

            let { serialNumber } = values;
            if (serialNumber.length > 20) {
                try {
                    groupSeparatorArray.forEach((gs, index) => serialNumber = [serialNumber.slice(0, gs + index), ' ', serialNumber.slice(gs + index)].join(''));
                    setValues(p => ({ ...p, serialNumber }))
                    setGroupSeparatorArray([]);

                    const barcode = dataMatrixCodec.decode(serialNumber);

                    parseBarcode(barcode);

                } catch (err) { console.log(err) }
            }
        }, 250)
    }, [values.serialNumber])

    useEffect(() => {
        M.updateTextFields();
    }, [values])

    return (
        <div className="row">
            <div className="input-field col s12 m2">
                <input id="serialNumber" type="text" value={values.serialNumber} onChange={onChange} onKeyDown={serialNumberKeyDown} tabIndex="1" />
                <label htmlFor="serialNumber">Serial #</label>
            </div>
            <div className="input-field col s12 m2">
                <input id="gtin" type="text" value={values.gtin} onChange={onChange} tabIndex="2" />
                <label htmlFor="gtin">GTIN</label>
            </div>
            <div className="input-field col s12 m1">
                <input id="lotNumber" type="text" value={values.lotNumber} onChange={onChange} tabIndex="3" />
                <label htmlFor="lotNumber">Lot #</label>
            </div>
            <div className="input-field col s12 m1">
                <input id="expirationDate" type="date" value={values.expirationDate} onChange={onChange} tabIndex="4" />
                <label htmlFor="expirationDate">Expiration</label>
            </div>
            <div className="input-field col s12 m3">
                <input className="autocomplete" id="medName" type="text" tabIndex="5" />
                <label htmlFor="medName">Med Name</label>
            </div>
            <div className="input-field col s12 m2">
                <input className="autocomplete" id="manufacturerName" type="text" tabIndex="6" />
                <label htmlFor="manufacturerName">Manufacturer</label>
            </div>
            <div className="input-field col s12 m1">
                <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" disabled={saving} onClick={saveMed} tabIndex="7">Save</a>
            </div>
        </div>
    )
}

export default MedInput;