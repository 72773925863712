import { useEffect, useLayoutEffect, useRef, useState } from "react";
import M from "materialize-css";
import Axios from "axios";
import { logout, toast } from "../../func";
import styled from "@emotion/styled";
import moment from "moment";

const WholesalerAccounts = props => {

    const getAuthData = () => JSON.parse(localStorage.getItem('auth_data'));

    const initValues = () => ({
        name: '',
        streetAddress: '',
        city: '',
        stateId: '0',
        zip: '',
        accountNumber: ''
    })

    const modalRef = useRef();

    const [editId, setEditId] = useState(undefined);
    const [values, setValues] = useState(initValues());
    const [stateList, setStateList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [accountList, setAccountList] = useState([]);

    useEffect(() => {
        getCustomerList();
        getStateList();
    }, [])

    useLayoutEffect(() => {
        M.Modal.init(modalRef.current, { dismissible: false });
        return () => M.Modal.getInstance(modalRef.current)?.destroy();
    }, [])

    const getCustomerList = () => {
        Axios.get('/api/v1/customer/read/many/by/userid', {
            params: getAuthData()
        })
            .then(result => {
                setCustomerList(result.data);
                setSelectedCustomerId(result.data[0].id);
            })
            .catch(logout);
    }

    const getWholesalerAccounts = () => {
        Axios.get('/api/v1/wholesaleraccount/read/many/by/customerid', {
            params: {
                ...getAuthData(),
                customerId: selectedCustomerId
            }
        })
            .then(result => setAccountList(result.data))
            .catch(logout);
    }

    const getStateList = () => {
        Axios.get('/api/v1/state/read/all', {
            params: getAuthData()
        })
            .then(result => {
                setStateList(result.data)
                M.FormSelect.init(document.querySelector('select'));
            })
            .catch(logout)
    }

    useEffect(() => {
        if (selectedCustomerId)
            getWholesalerAccounts();
    }, [selectedCustomerId])

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))



    const saveAccount = e => {
        e?.preventDefault();

        Axios.post('/api/v1/wholesaleraccount/create/one', {
            ...getAuthData(),
            customerId: selectedCustomerId,
            ...values
        })
            .then(result => {
                toast(result.data);
                getWholesalerAccounts();
                closeModal();
            })
            .catch(logout)
    }

    const updateAccount = e => {
        e?.preventDefault();

        Axios.post('/api/v1/wholesaleraccount/update/one', {
            ...getAuthData(),
            id: editId,
            ...values
        })
            .then(result => {
                toast(result.data);
                getWholesalerAccounts();
                closeModal();
            })
            .catch(logout);
    }

    const deleteAccount = e => {
        e?.preventDefault();

        if (window.confirm('Are you sure you want to delete this account?'))
            Axios.post('/api/v1/wholesaleraccount/delete/one', {
                ...getAuthData(),
                id: editId
            })
                .then(result => {
                    toast(result.data);
                    getWholesalerAccounts();
                    closeModal();
                })
                .catch(logout)
    }

    const openModal = e => {
        e?.preventDefault();
        M.Modal.getInstance(modalRef.current).open();
        M.FormSelect.init(document.querySelector('#stateId'));
        setTimeout(M.updateTextFields, 100);
    }

    const closeModal = e => {
        e?.preventDefault();
        M.Modal.getInstance(modalRef.current).close();
        setValues(initValues());
        setEditId(undefined);
        M.FormSelect.getInstance(document.querySelector('#stateId'))?.destroy();
    }

    const editAccount = (account) => {
        setEditId(account.id);
        setValues({
            name: account.name,
            streetAddress: account.streetAddress || '',
            city: account.city || '',
            stateId: account.state?.id || '0',
            zip: account.zip || '',
            accountNumber: account.accountNumber
        })

        openModal();
    }

    return (
        <div>
            <div className="row">
                <div className="input-field col s6 m2">
                    <select id="customerId" value={selectedCustomerId} onChange={({ target: { value } }) => setSelectedCustomerId(value)}>
                        {customerList?.map(customer => <option key={customer.id} value={customer.id}>{customer.name}</option>)}
                    </select>
                </div>
                <div className="col s12 m3 l2 offset-m7 offset-l10">
                    <a
                        href="/"
                        className="btn-small col s12 waves-effect waves-light blue white-text"
                        onClick={openModal}
                    >Add Account</a>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Zip</th>
                                <th>Account Number</th>
                                <th>Last Edited</th>
                                <th>Last Edited By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accountList && accountList.length ?
                                accountList?.map(acc => (
                                    <tr key={acc.id} onClick={() => editAccount(acc)} style={{ cursor: 'pointer' }}>
                                        <td style={{ padding: '2px' }}>{acc.name}</td>
                                        <td style={{ padding: '2px' }}>{acc.streetAddress || ''}</td>
                                        <td style={{ padding: '2px' }}>{acc.city || ''}</td>
                                        <td style={{ padding: '2px' }}>{acc.state?.code || ''}</td>
                                        <td style={{ padding: '2px' }}>{acc.zip || ''}</td>
                                        <td style={{ padding: '2px' }}>{acc.accountNumber}</td>
                                        <td style={{ padding: '2px' }}>{moment(acc.dateLastEdited ? acc.dateLastEdited : acc.dateCreated).format('MM/DD/YY')}</td>
                                        <td style={{ padding: '2px' }}>{acc.lastEditedBy ? `${acc.lastEditedBy.firstName} ${acc.lastEditedBy.lastName}s` : `${acc.createdBy.firstName} ${acc.createdBy.lastName}`}</td>
                                    </tr>
                                ))
                                :
                                <tr>
                                    <td style={{ padding: '2px' }} colSpan={8}>No Accounts to Display</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div ref={modalRef} className="modal modal-fixed-footer">
                <div className="modal-content">
                    <h4>{editId ? `Edit ${values.name} - ${values.accountNumber}` : 'New Account'}</h4>
                    <div className="row">
                        <div className="input-field col s12 l8">
                            <input id="name" type="text" value={values.name} onChange={onChange} />
                            <label htmlFor="name">Name</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 l6">
                            <input id="streetAddress" type="text" value={values.streetAddress} onChange={onChange} />
                            <label htmlFor="streetAddress">Street Address</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s8 l4">
                            <input id="city" type="text" value={values.city} onChange={onChange} />
                            <label htmlFor="city">City</label>
                        </div>
                        <div className="input-field col s4 l2">
                            <select id="stateId" value={values.stateId} onChange={onChange}>
                                <option value="0">Select A State</option>
                                {stateList?.map(state => <option key={state.id} value={state.id}>{state.code}</option>)}
                            </select>
                            <label htmlFor="stateId">State</label>
                        </div>
                        <div className="input-field col s2">
                            <input id="zip" type="text" value={values.zip} onChange={onChange} />
                            <label htmlFor="zip">Zip Code</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s8 l4">
                            <input id="accountNumber" type="text" value={values.accountNumber} onChange={onChange} />
                            <label htmlFor="accountNumber">Account Number</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    {editId ?
                        <div className="col s12 m2">
                            <a href="/" onClick={deleteAccount} className="btn-small col s12 black white-text waves-effect waves-dark">Delete Account</a>
                        </div>
                        : null}
                    <div className={`col s12 m2 ${editId ? 'offset-m6' : 'offset-m8'}`}>
                        <a href="/" onClick={editId ? updateAccount : saveAccount} className="btn-small col s12 blue white-text waves-effect waves-dark">{editId ? 'Update Account' : 'Save Account'}</a>
                    </div>
                    <div className="col s12 m2">
                        <a href="/" onClick={closeModal} className="btn-small col s12 red white-text waves-effect waves-dark">Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WholesalerAccounts;