import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { logout, toast } from '../../func';
import dataMatrixCodec from '../../utilities/dataMatrixCodec';

const SendPedigree = props => {

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const params = useParams();

    const ref = useRef({
        abortController: new AbortController(),
    })

    const initValues = () => ({
        serialNumber: '',
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        attachments: []
    })

    const [values, setValues] = useState(initValues());
    const [med, setMed] = useState(null);
    const [searching, setSearching] = useState(false);
    const [body, setBody] = useState(false);
    const [sending, setSending] = useState(false);

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    const getMed = () => {
        setSearching(true)
        setMed(null);
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();

        Axios.get('/api/v1/med/read/one/by/serialnumber', {
            params: {
                ...JSON.parse(localStorage.getItem('auth_data')),
                serialNumber: params.serialNumber
            },
            signal: ref.current.abortController?.signal
        })
            .then(result => setMed(result.data))
            .catch(logout)
            .finally(() => setSearching(false))
    }

    useEffect(() => {
        if (pathname !== values.currentSerialNumber && params.serialNumber)
            getMed()
    }, [pathname])

    const goToMed = e => {
        e?.preventDefault();
        setValues(p => ({ ...p, currentSerialNumber: values.serialNumber }))
        navigate(`/sendapedigree/${values.serialNumber}`, { replace: true })
    }

    const toggleAttachment = id => {
        const attachments = JSON.parse(JSON.stringify(values.attachments));
        const index = attachments.indexOf(id);
        if (index !== -1)
            attachments.splice(index, 1);
        else
            attachments.push(id);

        setValues(p => ({ ...p, attachments }))
    }

    const sendPedigree = e => {
        e?.preventDefault();
        setSending(true);

        Axios.post('/api/v1/med/email/one', {
            ...JSON.parse(localStorage.getItem('auth_data')),
            medId: med.med.id,
            to: values.to,
            cc: values.cc || '',
            subject: values.subject,
            body,
            attachments: values.attachments || [],
        })
            .then(result => toast(result.data))
            .catch(logout)
            .finally(() => setSending(false))
    }

    useEffect(() => {
        if (values.serialNumber && values.serialNumber.length > 20) {
            try {
                const data = dataMatrixCodec.decode(values.serialNumber);
                if (data['SERIAL NUMBER']) {
                    setValues(p => ({ ...p, serialNumber: data['SERIAL NUMBER'] }))
                }
                else {
                    setValues(p => ({ ...p, serialNumber: '' }))
                    toast({ msg: 'Serial Number not found in barcode', class: 'orange white-text' })
                }
            } catch (err) { console.log(err) }
        }
    }, [values.serialNumber])

    return (
        <div>
            <div className="row">
                <div className="input-field col s6 m4 l2">
                    <input id="serialNumber" type="text" value={values.serialNumber} onChange={onChange} />
                    <label htmlFor="serialNumber">Serial #</label>
                </div>
                <div className="input-field col s6 m3 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={goToMed}>Go</a>
                </div>
            </div>
            {med ?
                <div>
                    {searching ?
                        <div className="progress">
                            <div className="indeterminate" />
                        </div>
                        :
                        <>
                            <div className="row">
                                <div className="input-field col s12 m9">
                                    <sup className="right">Separate multiple emails with a semicolon ";"</sup>
                                    <input id="to" type="text" value={values.to} onChange={onChange} />
                                    <label htmlFor="to">To</label>
                                </div>
                                <div className="input-field col s12 m9" style={{ margin: '0px' }}>
                                    <input id="cc" type="text" value={values.cc} onChange={onChange} />
                                    <label htmlFor="cc">Cc</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12" style={{ margin: '0px' }}>
                                    <input id="subject" type="text" value={values.subject} onChange={onChange} />
                                    <label htmlFor="subject">Subject</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m8">
                                    <label>Email Body</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={body}
                                        onChange={setBody}
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline'],
                                                [{ list: 'ordered' }, { list: 'bullet' }],
                                                ['clean']
                                            ]
                                        }}
                                        formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                                    />
                                </div>
                            </div>
                            <div className="divider" />
                            <div className="row">
                                {med.attachments ?
                                    <div>
                                        <h6>Add Attachments</h6>
                                        {med.attachments.map(a => (
                                            <div key={a.id} className="input-field col s12">
                                                <p>
                                                    <label>
                                                        <input type="checkbox" className="filled-in" checked={values.attachments.includes(a.id)} onChange={() => toggleAttachment(a.id)} />
                                                        <span><b>{a.filename}</b></span>
                                                    </label>
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <p>This med has no attachments</p>
                                }
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m8">
                                    <div className="col s12 m3">
                                        <a
                                            href="/"
                                            className="btn-small blue white-text waves-effect waves-light col s12"
                                            onClick={sendPedigree}
                                            disabled={sending}
                                        >{sending ? 'Sending' : 'Send Pedigree'}</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                : <p>Your med could not be found, try searching for your med using its serial number in the field above.</p>}
        </div>
    )
}


export default SendPedigree;