import { useEffect, useLayoutEffect, useRef, useState } from "react";
import M from 'materialize-css';
import Axios from "axios";
import { logout, toast } from "../../func";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import dataMatrixCodec from "../../utilities/dataMatrixCodec";
const pageId = '_' + crypto.randomUUID();

const MedSearch = props => {

    const navigate = useNavigate();

    const initValues = () => ({
        serialNumber: '',
        ndc: '',
        medName: '',
        manufacturerName: '',
        lotNumber: '',
        locationId: 0,
        startDate: '',
        endDate: ''
    })

    const ref = useRef({
        abortController: new AbortController(),
        values: initValues()
    })

    const [values, setValues] = useState(initValues());
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [locationList, setLocationList] = useState(null);
    const [searching, setSearching] = useState(false);
    const [medList, setMedList] = useState(null);

    const getLocationList = () => {
        Axios.get('/api/v1/location/read/all/by/userid', {
            params: JSON.parse(localStorage.getItem('auth_data')),
        })
            .then(result => {
                setLocationList(result.data);
                setValues(p => ({ ...p, locationId: result.data[0]?.locationId }))
            })
            .catch(logout)
    }

    useEffect(() => {
        getLocationList();
    }, [])

    useLayoutEffect(() => {
        const enterListener = ({ keyCode }) => {
            if (keyCode === 13)
                search();
        }

        M.FormSelect.init(document.querySelectorAll('select'));
        document.querySelectorAll(`.search-med-${pageId}`).forEach(el => {
            el.addEventListener('keydown', enterListener)
        })

        return () => {
            document.querySelectorAll(`.search-med-${pageId}`).forEach(el => {
                el.removeEventListener('keydown', enterListener)
            })
            document.querySelectorAll('select').forEach(el => M.FormSelect.getInstance(el)?.destroy());
        }
    }, [])

    const onChange = ({ target: { id, value } }) => setValues(p => ({ ...p, [id]: value }))

    const toggleShowAdvancedSearch = e => {
        e?.preventDefault();
        setShowAdvancedSearch(p => !p);
    }

    useLayoutEffect(() => {
        if (showAdvancedSearch) {
            document.querySelectorAll('select').forEach(el => M.FormSelect.getInstance(el)?.destroy());
            M.FormSelect.init(document.querySelectorAll('select'));
        }
        else
            setValues(p => ({ ...p, locationId: 0 }))
    }, [showAdvancedSearch])

    const search = e => {
        e?.preventDefault();
        ref.current.abortController?.abort();
        ref.current.abortController = new AbortController();
        const { values } = ref.current;
        setSearching(true);

        Axios.get('/api/v1/med/read/many/by/search', {
            params: {
                ...JSON.parse(localStorage.getItem('auth_data')),
                serialNumber: values.serialNumber || '',
                ndc: values.ndc || '',
                medName: values.medName || '',
                manufacturerName: values.manufacturerName || '',
                lotNumber: values.lotNumber || '',
                locationId: values.locationId || 0,
                startDate: values.startDate || '',
                endDate: values.endDate || ''
            },
            signal: ref.current.abortController.signal
        })
            .then(result => setMedList(result.data))
            .catch(logout)
            .finally(() => setSearching(false));
    }

    const goToMed = med => navigate(`/meddetail/${med.id}`, false)

    useEffect(() => { ref.current.values = values }, [values])

    useEffect(() => {
        if (values.serialNumber && values.serialNumber.length > 20) {
            try {
                const data = dataMatrixCodec.decode(values.serialNumber);
                if (data['SERIAL NUMBER']) {
                    setValues(p => ({ ...p, serialNumber: data['SERIAL NUMBER'] }))
                }
                else {
                    setValues(p => ({ ...p, serialNumber: '' }))
                    toast({ msg: 'Serial Number not found in barcode', class: 'orange white-text' })
                }
            } catch (err) { console.log(err) }
        }
    }, [values.serialNumber])


    return (
        <div>
            <div className="row" style={{ marginTop: '8px' }}>
                <small className="right"><a className="col s12" href="/" onClick={toggleShowAdvancedSearch}>Advanced Search Options</a></small>
            </div>
            <div className="row">
                <div className="input-field col s12 m3 l2">
                    <input id="serialNumber" className={`search-med-${pageId}`} type="text" value={values.serialNumber} onChange={onChange} />
                    <label htmlFor="serialNumber">Serial #</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="ndc" className={`search-med-${pageId}`} type="text" value={values.ndc} onChange={onChange} />
                    <label htmlFor="ndc">NDC</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="medName" className={`search-med-${pageId}`} type="text" value={values.medName} onChange={onChange} />
                    <label htmlFor="medName">Med Name</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="manufacturerName" className={`search-med-${pageId}`} type="text" value={values.manufacturerName} onChange={onChange} />
                    <label htmlFor="manufacturerName">Manufacturer</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <input id="lotNumber" className={`search-med-${pageId}`} type="text" value={values.lotNumber} onChange={onChange} />
                    <label htmlFor="lotNumber">Lot #</label>
                </div>
                <div className="input-field col s12 m3 l2">
                    <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={search}>Search Meds</a>
                </div>
            </div>
            {showAdvancedSearch ?
                <div className="row">
                    {locationList && locationList.length > 1 ?
                        <div className="input-field col s12 m4 l3">
                            <select id="locationId" value={values.locationId} onChange={onChange}>
                                <option value={0}>Any</option>
                                {locationList?.map(f => <option key={f.id} value={f.id}>{f.name}</option>)}
                            </select>
                        </div>
                        : null}
                    <div className="input-field col s12 m3 l2">
                        <input id="startDate" className={`search-med-${pageId}`} type="date" value={values.startDate} onChange={onChange} />
                        <label htmlFor="startDate">Input Date Range Start</label>
                    </div>
                    <div className="input-field col s12 m3 l2">
                        <input id="endDate" className={`search-med-${pageId}`} type="date" value={values.endDate} onChange={onChange} />
                        <label htmlFor="endDate">Input Date Range End</label>
                    </div>
                </div>
                : null}
            <div className="row">
                {searching ?
                    <div className="progress">
                        <div className="indeterminate" />
                    </div>
                    :
                    <table>
                        <thead>
                            <tr>
                                <th style={{ padding: '2px' }}>Serial #</th>
                                <th style={{ padding: '2px' }}>NDC</th>
                                <th style={{ padding: '2px' }}>Med Name</th>
                                <th style={{ padding: '2px' }}>Manufacturer</th>
                                <th style={{ padding: '2px' }}>Lot #</th>
                                <th style={{ padding: '2px' }}>Date Created</th>
                                <th style={{ padding: '2px' }}>Created By</th>
                                <th style={{ padding: '2px' }}>Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {medList?.map(row => (
                                <tr key={row.med.id} onClick={() => goToMed(row.med)} style={{ cursor: 'pointer' }}>
                                    <td style={{ padding: '2px' }}>{row.med.serialNumber}</td>
                                    <td style={{ padding: '2px' }}>{row.redbook.ndc}</td>
                                    <td style={{ padding: '2px' }}>{row.redbookDetail.medName}</td>
                                    <td style={{ padding: '2px' }}>{row.redbookDetail.manufacturerName}</td>
                                    <td style={{ padding: '2px' }}>{row.med.lotNumber}</td>
                                    <td style={{ padding: '2px' }}>{moment(row.med.timeStamp).format('MM/DD/YY')}</td>
                                    <td style={{ padding: '2px' }}>{row.med.createdBy.firstName}{row.med.createdBy.lastName}</td>
                                    <td style={{ padding: '2px' }}>{row.location.name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default MedSearch;