import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const MedTable = props => {
    const {
        medList,
        loadingMedList
    } = props;

    const navigate = useNavigate();

    const goToMedDetail = id => navigate(`/meddetail/${id}`, false)

    return (
        <>
            {loadingMedList ?
                <div className='progress'>
                    <div className='indeterminate' />
                </div>
                :
                <table className='highlight'>
                    <thead>
                        <tr>
                            <th>Serial #</th>
                            <th>NDC</th>
                            <th>Lot #</th>
                            <th>Med Name</th>
                            <th>Manufacturer</th>
                            <th>Expiration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {medList?.map(m => (
                            <tr key={m.med.id} style={{ cursor: 'pointer' }} onClick={e => goToMedDetail(m.med.id)}>
                                <td style={{ padding: '2px' }}>{m.med.serialNumber}</td>
                                <td style={{ padding: '2px' }}>{m.redbook.ndc}</td>
                                <td style={{ padding: '2px' }}>{m.med.lotNumber}</td>
                                <td style={{ padding: '2px' }}>{m.redbookDetail.medName}</td>
                                <td style={{ padding: '2px' }}>{m.redbookDetail.manufacturerName}</td>
                                <td style={{ padding: '2px' }}>{m.med.expirationDate ? moment(m.expirationDate).format('MM/DD/YY') : null}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </>
    )
}

export default MedTable;