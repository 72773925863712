import {  useRef, useState } from "react";
import { toast } from '../../func';
import FileUploader from "../../utilities/fileUploader";

const OrderUpload = props => {

    const fileInput = useRef();

    const [uploading, setUploading] = useState(false);

    const upload = e => {
        e.preventDefault();
        const [file] = fileInput.current.files;

        if (!file)
            toast({ payload: { msg: 'No file selected', class: 'red white-text' } })
        else {
            // setUploading(true);

            const fileUploader = new FileUploader(
                [file],
                '/api/v1/file/upload/order'
            );

            fileUploader.setOnUploadFinish(() => setUploading(false))

            fileUploader.upload();
        }
    }

    return (
        <div>
            <div className="row">
                <h6>You can upload orders here if you are not an EDI customer or if one of your orders is not showing up on the receivables page.</h6>
                <h6>All orders must be EPCIS documents in XML format (.xml file extension)</h6>
                <h6>If you have a different file format, contact MedTrax support.</h6>
            </div>
            <div className="row">
                <div className="file-field input-field col s12 m4">
                    <div className="btn-small blue white-text" style={{ maxHeight: '30px', lineHeight: '30px', position: 'relative', top: '10px' }}>
                        <span>Browse</span>
                        <input ref={fileInput} type="file" />
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path" type="text" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12 m2">
                    <a
                        className="btn-small blue white-text waves-effect waves-light col s12"
                        href="/"
                        onClick={upload}
                        disabled={uploading}
                    >Upload</a>
                </div>
            </div>
        </div>
    )
}


export default OrderUpload;