import Axios from "axios";
import { logout } from "../../func";

const DemoEpcisFileGenerator = props => {
    const getAuthData = () => JSON.parse(localStorage.getItem('auth_data'));

    const download = e => {
        e?.preventDefault();

        Axios.post('/api/v1/order/create/one/demoepcisfile', getAuthData())
            .then(result => {

                const { epcisFilename, epcisBuffer, epcisBarcodeBuffer, epcisBarcodeFilename } = result.data;
                const epcisUint8Array = Uint8Array.from(epcisBuffer.data);
                const barcodeUint8Array = Uint8Array.from(epcisBarcodeBuffer.data);
                const epcisBlob = new Blob([epcisUint8Array]);
                const barcodeBlob = new Blob([barcodeUint8Array]);
                const epcisUrl = window.URL.createObjectURL(epcisBlob);
                const barcodeUrl = window.URL.createObjectURL(barcodeBlob);
                const link = document.createElement('a');
                link.hidden = true;

                //download epcis file
                link.href = epcisUrl; 
                link.setAttribute('download', epcisFilename);
                document.body.appendChild(link);
                link.click();

                //download barcode file
                link.href = barcodeUrl;
                link.setAttribute('download', epcisBarcodeFilename);
                link.click();

                document.body.removeChild(link);

            })
            .catch(logout);
    }

    return (
        <div>
            <div className="row">
                <h5>The files generated on this page are intended for use by MedTrax sales representatives only</h5>
                <h5>The file generated are random and do not represent real orders.</h5>
                <h5>If you have questions about the EPCIS files call MedTrax IT</h5>
            </div>
            <div className="row">
                <div className="col s12 m3 l2">
                    <a href="/" onClick={download} className="btn-small blue white-text waves-effect waves-light">Download Sample</a>
                </div>
            </div>
        </div>
    )
}

export default DemoEpcisFileGenerator