import { useState, useEffect } from 'react';
import Axios from 'axios';
import UsernameAndPassword from './UsernameAndPassword';
import SecurityCode from './SecurityCode';
import ForgotPassword from './ForgotPassword';
import { Routes, Route } from 'react-router-dom';


const Login = props => {
    const { setLoggedIn } = props;
    const [firstFactor, setFirstFactor] = useState(false);

    useEffect(() => {
        const authData = localStorage.getItem('auth_data');

        if (authData) {
            Axios.post('/api/v1/auth/update/one/token', JSON.parse(authData))
                .then(() => setLoggedIn(true))
                .catch(err => {
                    localStorage.clear()
                })
        }
    }, [])


    return (
        <Routes>
            <Route path="/" element={firstFactor ? <SecurityCode setFirstFactor={setFirstFactor} setLoggedIn={setLoggedIn} /> : <UsernameAndPassword setFirstFactor={setFirstFactor} />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
        </Routes>
    )
}

export default Login;