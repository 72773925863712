
//ALL LENGTHS !!!DO NOT!!! INCLUDE THE APPLICATION IDENTIFIER NUMBER

// THE "n" VARIABLE IS FOR AIS THAT INCLUDE A THIRD DIGIT SIGNIFYING HOW MANY CHARACTERS LONG THE DATA FIELD IS
// THE "y" VARIABLE SIGNIFIES HOW MANY OF THE DIGITS ARE AFTER THE DECIMAL PLACE
// "n" and "y" values are always single digit
// FOR EXAMPLE 239 IS A LOT NUMBER 9 CHARACTERS LONG

const aiDescription = {
    SERIAL_SHIPPING_CONTAINER_CODE: 'SERIAL SHIPPING CONTAINER CODE (SSCC)',
    GLOBAL_TRADE_ITEM_NUMBER: 'GLOBAL TRADE ITEM NUMBER (GTIN)',
    GTIN_OF_CONTAINED_TRADE_ITEMS: 'GTIN OF CONTAINED TRADE ITEMS',
    BATCH_OR_LOT_NUMBER: 'BATCH/LOT NUMBER',
    PRODUCTION_DATE: 'PRODUCTION DATE',
    DUE_DATE: 'DUE DATE',
    PACKAGING_DATE: 'PACKAGING DATE',
    BEST_BEFORE_DATE_YYMMDD: 'BEST BEFORE DATE (YYMMDD)',
    EXPIRATION_DATE_YYMMDD: 'EXPIRATION DATE (YYMMDD)',
    PRODUCT_VARIANT: 'PRODUCT VARIANT',
    SERIAL_NUMBER: 'SERIAL NUMBER',
    SECONDARY_DATA_FIELDS: 'SECONDARY DATA FIELDS',
    LOT_NUMBER_N: 'LOT NUMBER N',
    ADDITIONAL_PRODUCT_IDENTIFICATION: 'ADDITIONAL PRODUCT IDENTIFICATION',
    CUSTOMER_PART_NUMBER: 'CUSTOMER PART NUMBER',
    MADE_TO_ORDER_VARIATION_NUMBER: 'MADE-TO-ORDER VARIATION NUMBER',
    SECONDARY_SERIAL_NUMBER: 'SECONDARY SERIAL NUMBER',
    REFERENCE_TO_SOURCE_ENTITY: 'REFERENCE TO SOURCE ENTITY',
    GLOBAL_DOCUMENT_TYPE_IDENTIFIER: 'GLOBAL DOCUMENT TYPE IDENTIFIER',
    GLN_EXTENSION_COMPONENT: 'GLN EXTENSION COMPONENT',
    COUNT_OF_ITEMS: 'COUNT OF ITEMS',
    PRODUCT_NET_WEIGHT_IN_KG: 'PRODUCT NET WEIGHT IN KG',
    PRODUCT_LENGTH_IN_METERS: 'PRODUCT LENGTH/FIRST DIMENSION IN METERS',
    PRODUCT_WIDTH_IN_METERS: 'PRODUCT WIDTH/DIAMETER/SECOND DIMENSION IN METERS',
    PRODUCT_DEPTH_IN_METERS: 'PRODUCT DEPTH/THICKNES/HEIGHT/THIRD DIMENSION IN METERS',
    PRODUCT_AREA_IN_SQUARE_METERS: 'PRODUCT AREA IN SQUARE METERS',
    PRODUCT_NET_VOLUME_IN_LITERS: 'PRODUCT NET VOLUME IN LITERS',
    PRODUCT_NET_VOLUME_IN_CUBIC_METERS: 'PRODUCT NET VOLUME IN CUBIC METERS',
    PRODUCT_NET_WEIGHT_IN_LBS: 'PRODUCT NET WEIGHT IN LBS',
    PRODUCT_LENGTH_IN_INCHES: 'PRODUCT LENGTH/FIRST DIMENSION IN INCHES',
    PRODUCT_LENGTH_IN_FEET: 'PRODUCT LENGTH/FIRST DIMENSION IN FEET',
    PRODUCT_LENGTH_IN_YARDS: 'PRODUCT LENGTH/FIRST DIMENSION IN YARDS',
    PRODUCT_WIDTH_IN_INCHES: 'PRODUCT WIDTH/DIAMETER/SECOND DIMENSION IN INCHES',
    PRODUCT_WIDTH_IN_FEET: 'PRODUCT WIDTH/DIAMETER/SECOND DIMENSION IN FEET',
    PRODUCT_WIDTH_IN_YARDS: 'PRODUCT WIDTH/DIAMETER/SECOND DIMENSION IN YARDS',
    PRODUCT_DEPTH_IN_INCHES: 'PRODUCT DEPTH/THICKNESS/HEIGHT/THIRD DIMENSION IN INCHES',
    PRODUCT_DEPTH_IN_FEET: 'PRODUCT DEPTH/THICKNESS/HEIGHT/THIRD DIMENSION IN FEET',
    PRODUCT_DEPTH_IN_YARDS: 'PRODUCT DEPTH/THICKNESS/HEIGHT/THIRD DIMENSION IN YARDS',
    CONTAINER_GROSS_WEIGHT_KG: 'CONTAINER GROSS WEIGHT IN KG',
    CONTAINER_LENGTH_IN_METERS: 'CONTAINER LENGTH/FIRST DIMENSION IN METERS',
    CONTAINER_WIDTH_IN_METERS: 'CONTAINER WIDTH/DIAMETER/SECOND DIMENSION IN METERS',
    CONTAINER_DEPTH_IN_METERS: 'CONTAINER DEPTH/THICKNESS/THIRD DIMENSION IN METERS',
    CONTAINER_AREA_IN_SQUARE_METERS: 'CONTAINER AREA IN SQUARE METERS',
    CONTAINER_GROSS_VOLUME_IN_LITERS: 'CONTAINER GROSS VOLUME IN LITERS',
    CONTAINER_GROSS_VOLUME_IN_CUBIC_METERS: 'CONTAINER GROSS VOLUME IN CUBIC METERS',
    CONTAINER_GROSS_WEIGHT_IN_LBS: 'CONTAINER GROSS WEIGHT IN LBS',
    CONTAINER_LENGTH_IN_INCHES: 'CONTAINER LENGTH/FIRST DIMENSION IN INCHES',
    CONTAINER_LENGTH_IN_FEET: 'CONTAINER LENGTH/FIRST DIMENSION IN FEET',
    CONTAINER_LENGTH_IN_YARDS: 'CONTAINER LENGTH/FIRST DIMENSION IN YARDS',
    CONTAINER_WIDTH_IN_INCHES: 'CONTAINER WIDTH/DIAMETER/SECOND DIMENSION IN INCHES',
    CONTAINER_WIDTH_IN_FEET: 'CONTAINER WIDTH/DIAMETER/SECOND DIMENSION IN FEET',
    CONTAINER_WIDTH_IN_YARDS: 'CONTAINER WIDTH/DIAMETER/SECOND DIMENSION IN YARDS',
    CONTAINER_DEPTH_IN_INCHES: 'CONTAINER DEPTH/THICKNESS/HEIGHT/THIRD DIMENSION IN INCHES',
    CONTAINER_DEPTH_IN_FEET: 'CONTAINER DEPTH/THICKNESS/HEIGHT/THIRD DIMENSION IN FEET',
    CONTAINER_DEPTH_IN_YARDS: 'CONTAINER DEPTH/THICKNESS/HEIGHT/THIRD DIMENSION IN YARDS',
    PRODUCT_AREA_IN_SQUARE_INCHES: 'PRODUCT AREA IN SQUARE INCHES',
    PRODUCT_AREA_IN_SQUARE_FEET: 'PRODUCT AREA IN SQUARE FEET',
    PRODUCT_AREA_IN_SQUARE_YARDS: 'PRODUCT AREQ IN SQUARE YARDS',
    CONTAINER_AREA_IN_SQUARE_INCHES: 'CONTAINER AREA IN SQUARE INCHES',
    CONTAINER_AREA_IN_SQUARE_FEET: 'CONTAINER AREA IN SQUARE FEET',
    CONTAINER_AREA_IN_SQUARE_YARDS: 'CONTAINER AREQ IN SQUARE YARDS',
    NET_WEIGHT_IN_TROY_OUNCES: 'NET WEIGHT IN TROY OUNCES',
    NET_WEIGHT_OR_VOLUME_IN_OUNCES: 'NET WEIGHT/VOLUME IN OUNCES',
    PRODUCT_VOLUME_IN_QUARTS: 'PRODUCT VOLUME IN QUARTS',
    PRODUCT_VOLUME_IN_GALLONS: 'PRODUCT VOLUME IN GALLONS',
    CONTAINER_GROSS_VOLUME_IN_QUARTS: 'CONTAINER GROSS VOLUME IN QUARTS',
    CONTAINER_GROSS_VOLUME_IN_GALLONS: 'CONTAINER GROSS VOLUME IN GALLONS',
    PRODUCT_VOLUME_IN_CUBIC_INCHES: 'PRODUCT VOLUME IN CUBIC INCHES',
    PRODUCT_VOLUME_IN_CUBIC_FEET: 'PRODUCT VOLUME IN CUBIC FEET',
    PRODUCT_VOLUME_IN_CUBIC_YARDS: 'PRODUCT VOLUME IN CUBIC YARDS',
    CONTAINER_GROSS_VOLUME_IN_CUBIC_INCHES: 'CONTAINER GROSS VOLUME IN CUBIC INCHES',
    CONTAINER_GROSS_VOLUME_IN_CUBIC_FEET: 'CONTAINER GROSS VOLUME IN CUBIC FEET',
    CONTAINER_GROSS_VOLUME_IN_CUBIC_YARDS: 'CONTAINER GROSS VOLUME IN CUBIC YARDS',
    NUMBER_OF_UNITS_CONTAINED: 'NUMBER OF UNITS CONTAINED',
    AMOUNT_PAYABLE_IN_LOCAL_CURRENCY: 'AMOUNT PAYABLE IN LOCAL CURRENCY',
    AMOUNT_PAYABLE_WITH_ISO_CURRENCY_CODE: 'AMOUNT PAYABLE WITH ISO CURRENCY CODE',
    AMOUNT_PAYABLE_PER_ITEM_LOCAL_CURRENCY: 'AMOUNT PAYABLE PER ITEM IN LOCAL CURRENCY',
    AMOUNT_PAYABLE_PER_ITEM_WITH_ISO_CURRENCY_CODE: 'AMOUNT PAYABLE PER ITEM WITH ISO CURRENCY CODE',
    CUSTOMER_PURCHASE_ORDER_NUMBER: 'CUSTOMER PURCHASE ORDER NUMBER',
    CONSIGNMENT_NUMBER: 'CONSIGNMENT NUMBER',
    BILL_OF_LADING_NUMBER: 'BILL OF LADING NUMBER',
    ROUTING_CODE: 'ROUTING CODE',
    SHIP_TO_GLOBAL_LOCATION_NUMBER: 'SHIP TO/DELIVER TO LOCATION AS GLOBAL LOCATION NUMBER',
    BILL_TO_GLOBAL_LOCATION_NUMBER: 'BILL TO/INVOICE LOCATION AS GLOBAL LOCATION NUMBER',
    PURCHASE_FROM_GLOBAL_LOCATION_NUMBER: 'PURCHASE FROM LOCATION AS GLOBAL LOCATION NUMBER',
    SHIP_FOR_GLOBAL_LOCATION_NUMBER: 'SHIP FOR/DELIVER FOR/FORWARD TO LOCATION AS GLOBAL LOCATION NUMBER',
    IDENTIFICATION_OF_A_PHYSICAL_LOCATION: 'IDENTIFICATION OF A PHYSICAL LOCATION AS A GLOBAL LOCATION NUMBER',
    SHIP_TO_POSTAL_CODE: 'SHIP TO/DELIVER TO POSTAL CODE (SINGLE POSTAL AUTHORITY)',
    SHIP_TO_POSTAL_CODE_WITH_ISO_COUNTRY_CODE: 'SHIP TO/DELIVER TO POSTAL CODE WITH ISO COUNTRY CODE',
    COUNTRY_OF_ORIGIN_ISO_COUNTRY_CODE: 'COUNTRY OF ORIGIN AS ISO COUNTRY CODE',
    COUNTRY_OR_COUNTRIES_OF_INITIAL_PROCESSING: 'COUNTRY OR COUNTRIES OF INITIAL PROCESSING',
    COUNTRY_OF_PROCESSING: 'COUNTRY OF PROCESSING',
    COUNTRY_OF_DISASSEMBLY: 'COUNTRY OF DISASSEMBLY',
    COUNTRY_OF_FULL_PROCESS_CHAIN: 'COUNTRY OF FULL PROCESS CHAIN',
    NATO_STOCK_NUMBER: 'NATO STOCK NUMBER',
    UN_ECE_MEAT_CARCASSES_AND_CUTS_CLASSIFICATION: 'UN/ECE MEAT CARCASSES AND CUTS CLASSIFICATION',
    EXPIRATION_DATE_AND_TIME: 'EXPIRATION DATE AND TIME',
    ACTIVE_POTENCY: 'ACTIVE POTENCY',
    PROCESSOR_APPROVAL_WITH_ISO_COUNTRY_CODE: 'PROCESSOR APPROVAL WITH ISO COUNTRY CODE (n INDICATES SEQUENCE NUMBER OF SEVERAL PROCESSORS',
    ROLL_PRODUCTS_DIMENSIONS: 'ROLL PRODUCTS WIDTH/LENGTH/CORE DIAMETER/DIRECTION/SPLICES',
    MOBILE_PHONE_IDENTIFIER: 'MOBILE PHONE IDENTIFIER',
    GLOBAL_RETURNABLE_ASSET_IDENTIFIER: 'GLOBAL RETURNABLE ASSET IDENTIFIER',
    GLOBAL_INDIVIDUAL_ASSET_IDENTIFIER: 'GLOBAL INDIVIDUAL ASSET IDENTIFIER',
    PRICE_PER_UNIT_OF_MEASURE: 'PRICE PER UNIT OF MEASURE',
    IDENTIFICATION_OF_THE_COMPONENTS_OF_AN_ITEM: 'IDENTIFICATION OF THE COMPONENTS OF AN ITEM',
    INTERNATIONAL_BANK_ACCOUNT_NUMBER: 'INTERNATIONAL BANK ACCOUNT NUMBER',
    DATE_TIME_OF_PRODUCTION: 'DATE AND TIME OF PRODUCTION',
    GLOBAL_SERVICE_RELATION_NUMBER: 'GLOBAL SERVICE RELATION NUMBER',
    PAYMENT_SLIP_REFERENCE_NUMBER: 'PAYMENT SLIP REFERENCE NUMBER',
    COUPON_EXTENDED_CODE_NUMBER_SYSTEM_AND_OFFER: 'COUPON EXTENDED CODE: NUMBER, SYSTEM, AND OFFER',
    COUPON_EXTENDED_CODE_NUMBER_SYSTEM_OFFER_AND_END_OF_OFFER: 'COUPON EXTENDED CODE: NUMBER, SYSTEM, OFFER, AND END OF OFFER',
    COUPON_EXTENDED_CODE_NUMBER_SYSTEM_PRECEDED_BY_0: 'COUPON EXTENDED OFFER NUMBER AND SYSTEM PRECEDED BY 0',
    COUPON_CODE_ID_NORTH_AMERICA: 'COUPON CODE ID (NORTH AMERICA)',
    MUTUALLY_AGREED_VARIABLE_BETWEEN_TRADING_PARTNERS: 'THIS IS A PLACEHOLDER FOR A VARIABLE DETERMINED BY TRADING PARTNERS',
    INTERNAL_COMPANY_CODE: 'INTERNAL COMPANY CODE'
};


const applicationIdentifiers = {
    '00': { name: aiDescription.SERIAL_SHIPPING_CONTAINER_CODE, length: 18 },
    '01': { name: aiDescription.GLOBAL_TRADE_ITEM_NUMBER, length: 14 },
    '02': { name: aiDescription.GTIN_OF_CONTAINED_TRADE_ITEMS, length: 14 },
    '10': { name: aiDescription.BATCH_OR_LOT_NUMBER, maxLength: 20 },
    '11': { name: aiDescription.PRODUCTION_DATE, length: 6 },
    '12': { name: aiDescription.DUE_DATE, length: 6 },
    '13': { name: aiDescription.PACKAGING_DATE, length: 6 },
    '15': { name: aiDescription.BEST_BEFORE_DATE_YYMMDD, length: 6 },
    '17': { name: aiDescription.EXPIRATION_DATE_YYMMDD, length: 6 },
    '20': { name: aiDescription.PRODUCT_VARIANT, length: 2 },
    '21': { name: aiDescription.SERIAL_NUMBER, maxLength: 20 },
    '22': { name: aiDescription.SECONDARY_DATA_FIELDS, maxLength: 29 },
    '23': { name: aiDescription.LOT_NUMBER_N, n: true, maxLength: 19 },
    '240': { name: aiDescription.ADDITIONAL_PRODUCT_IDENTIFICATION, maxLength: 30 },
    '241': { name: aiDescription.CUSTOMER_PART_NUMBER, maxLength: 30 },
    '242': { name: aiDescription.MADE_TO_ORDER_VARIATION_NUMBER, maxLength: 6 },
    '250': { name: aiDescription.SECONDARY_SERIAL_NUMBER, maxLength: 30 },
    '251': { name: aiDescription.REFERENCE_TO_SOURCE_ENTITY, maxLength: 30 },
    '253': { name: aiDescription.GLOBAL_DOCUMENT_TYPE_IDENTIFIER, maxLength: 30 },
    '254': { name: aiDescription.GLN_EXTENSION_COMPONENT, maxLength: 20 },
    '30': { name: aiDescription.COUNT_OF_ITEMS, maxLength: 8 },
    '310': { name: aiDescription.PRODUCT_NET_WEIGHT_IN_KG, y: true, length: 6 },
    '311': { name: aiDescription.PRODUCT_LENGTH_IN_METERS, y: true, length: 6 },
    '312': { name: aiDescription.PRODUCT_WIDTH_IN_METERS, y: true, length: 6 },
    '313': { name: aiDescription.PRODUCT_DEPTH_IN_METERS, y: true, length: 6 },
    '314': { name: aiDescription.PRODUCT_AREA_IN_SQUARE_METERS, y: true, length: 6 },
    '315': { name: aiDescription.PRODUCT_NET_VOLUME_IN_LITERS, y: true, length: 6 },
    '316': { name: aiDescription.PRODUCT_NET_VOLUME_IN_CUBIC_METERS, y: true, length: 6 },
    '320': { name: aiDescription.PRODUCT_NET_WEIGHT_IN_LBS, y: true, length: 6 },
    '321': { name: aiDescription.PRODUCT_LENGTH_IN_INCHES, y: true, length: 6 },
    '322': { name: aiDescription.PRODUCT_LENGTH_IN_FEET, y: true, length: 6 },
    '323': { name: aiDescription.PRODUCT_LENGTH_IN_YARDS, y: true, length: 6 },
    '324': { name: aiDescription.PRODUCT_WIDTH_IN_INCHES, y: true, length: 6 },
    '325': { name: aiDescription.PRODUCT_WIDTH_IN_FEET, y: true, length: 6 },
    '326': { name: aiDescription.PRODUCT_WIDTH_IN_YARDS, y: true, length: 6 },
    '327': { name: aiDescription.PRODUCT_DEPTH_IN_INCHES, y: true, length: 6 },
    '328': { name: aiDescription.PRODUCT_DEPTH_IN_FEET, y: true, length: 6 },
    '329': { name: aiDescription.PRODUCT_DEPTH_IN_YARDS, y: true, length: 6 },
    '330': { name: aiDescription.CONTAINER_GROSS_WEIGHT_KG, y: true, length: 6 },
    '331': { name: aiDescription.CONTAINER_LENGTH_IN_METERS, y: true, length: 6 },
    '332': { name: aiDescription.CONTAINER_WIDTH_IN_METERS, y: true, length: 6 },
    '333': { name: aiDescription.CONTAINER_DEPTH_IN_METERS, y: true, length: 6 },
    '334': { name: aiDescription.CONTAINER_AREA_IN_SQUARE_METERS, y: true, length: 6 },
    '335': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_LITERS, y: true, length: 6 },
    '336': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_CUBIC_METERS, y: true, length: 6 },
    '340': { name: aiDescription.CONTAINER_GROSS_WEIGHT_IN_LBS, y: true, length: 6 },
    '341': { name: aiDescription.CONTAINER_LENGTH_IN_INCHES, y: true, length: 6 },
    '342': { name: aiDescription.CONTAINER_LENGTH_IN_FEET, y: true, length: 6 },
    '343': { name: aiDescription.CONTAINER_LENGTH_IN_YARDS, y: true, length: 6 },
    '344': { name: aiDescription.CONTAINER_WIDTH_IN_INCHES, y: true, length: 6 },
    '345': { name: aiDescription.CONTAINER_WIDTH_IN_FEET, y: true, length: 6 },
    '346': { name: aiDescription.CONTAINER_WIDTH_IN_YARDS, y: true, length: 6 },
    '347': { name: aiDescription.CONTAINER_DEPTH_IN_INCHES, y: true, length: 6 },
    '348': { name: aiDescription.CONTAINER_DEPTH_IN_FEET, y: true, length: 6 },
    '349': { name: aiDescription.CONTAINER_DEPTH_IN_YARDS, y: true, length: 6 },
    '350': { name: aiDescription.PRODUCT_AREA_IN_SQUARE_INCHES, y: true, length: 6 },
    '351': { name: aiDescription.PRODUCT_AREA_IN_SQUARE_FEET, y: true, length: 6 },
    '352': { name: aiDescription.PRODUCT_AREA_IN_SQUARE_YARDS, y: true, length: 6 },
    '353': { name: aiDescription.CONTAINER_AREA_IN_SQUARE_INCHES, y: true, length: 6 },
    '354': { name: aiDescription.CONTAINER_AREA_IN_SQUARE_FEET, y: true, length: 6 },
    '355': { name: aiDescription.CONTAINER_AREA_IN_SQUARE_YARDS, y: true, length: 6 },
    '356': { name: aiDescription.NET_WEIGHT_IN_TROY_OUNCES, y: true, length: 6 },
    '357': { name: aiDescription.NET_WEIGHT_OR_VOLUME_IN_OUNCES, y: true, length: 6 },
    '360': { name: aiDescription.PRODUCT_VOLUME_IN_QUARTS, y: true, length: 6 },
    '361': { name: aiDescription.PRODUCT_VOLUME_IN_GALLONS, y: true, length: 6 },
    '362': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_QUARTS, y: true, length: 6 },
    '363': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_GALLONS, y: true, length: 6 },
    '364': { name: aiDescription.PRODUCT_VOLUME_IN_CUBIC_INCHES, y: true, length: 6 },
    '365': { name: aiDescription.PRODUCT_VOLUME_IN_CUBIC_FEET, y: true, length: 6 },
    '366': { name: aiDescription.PRODUCT_VOLUME_IN_CUBIC_YARDS, y: true, length: 6 },
    '367': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_CUBIC_INCHES, y: true, length: 6 },
    '368': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_CUBIC_FEET, y: true, length: 6 },
    '369': { name: aiDescription.CONTAINER_GROSS_VOLUME_IN_CUBIC_YARDS, y: true, length: 6 },
    '37': { name: aiDescription.NUMBER_OF_UNITS_CONTAINED, maxLength: 8 },
    '390': { name: aiDescription.AMOUNT_PAYABLE_IN_LOCAL_CURRENCY, y: true, maxLength: 15 },
    '391': { name: aiDescription.AMOUNT_PAYABLE_WITH_ISO_CURRENCY_CODE, y: true, minLength: 3, maxLength: 18 },
    '392': { name: aiDescription.AMOUNT_PAYABLE_PER_ITEM_LOCAL_CURRENCY, y: true, maxLength: 15 },
    '393': { name: aiDescription.AMOUNT_PAYABLE_PER_ITEM_WITH_ISO_CURRENCY_CODE, y: true, minLength: 3, maxLength: 18 },
    '400': { name: aiDescription.CUSTOMER_PURCHASE_ORDER_NUMBER, maxLength: 30 },
    '401': { name: aiDescription.CONSIGNMENT_NUMBER, maxLength: 30 },
    '402': { name: aiDescription.BILL_OF_LADING_NUMBER, length: 17 },
    '403': { name: aiDescription.ROUTING_CODE, maxLength: 30 },
    '410': { name: aiDescription.SHIP_TO_GLOBAL_LOCATION_NUMBER, length: 13 },
    '411': { name: aiDescription.BILL_TO_GLOBAL_LOCATION_NUMBER, length: 13 },
    '412': { name: aiDescription.PURCHASE_FROM_GLOBAL_LOCATION_NUMBER, length: 13 },
    '413': { name: aiDescription.SHIP_FOR_GLOBAL_LOCATION_NUMBER, length: 13 },
    '414': { name: aiDescription.IDENTIFICATION_OF_A_PHYSICAL_LOCATION, length: 13 },
    '420': { name: aiDescription.SHIP_TO_POSTAL_CODE, maxLength: 20 },
    '421': { name: aiDescription.SHIP_TO_POSTAL_CODE_WITH_ISO_COUNTRY_CODE, minLength: 3, maxLength: 15 },
    '422': { name: aiDescription.COUNTRY_OF_ORIGIN_ISO_COUNTRY_CODE, length: 3 },
    '423': { name: aiDescription.COUNTRY_OR_COUNTRIES_OF_INITIAL_PROCESSING, minLength: 3, maxLength: 15 },
    '424': { name: aiDescription.COUNTRY_OF_PROCESSING, length: 3 },
    '425': { name: aiDescription.COUNTRY_OF_DISASSEMBLY, length: 3 },
    '426': { name: aiDescription.COUNTRY_OF_FULL_PROCESS_CHAIN, length: 3 },
    '7001': { name: aiDescription.NATO_STOCK_NUMBER, length: 13 },
    '7002': { name: aiDescription.UN_ECE_MEAT_CARCASSES_AND_CUTS_CLASSIFICATION, maxLength: 30 },
    '7003': { name: aiDescription.EXPIRATION_DATE_AND_TIME, length: 10 },
    '7004': { name: aiDescription.ACTIVE_POTENCY, maxLength: 4 },
    '703': { name: aiDescription.PROCESSOR_APPROVAL_WITH_ISO_COUNTRY_CODE, n: true, minLength: 3, maxLength: 30 },
    '8001': { name: aiDescription.ROLL_PRODUCTS_DIMENSIONS, length: 14 },
    '8002': { name: aiDescription.MOBILE_PHONE_IDENTIFIER, maxLength: 20 },
    '8003': { name: aiDescription.GLOBAL_RETURNABLE_ASSET_IDENTIFIER, minLength: 14, maxLength: 30 },
    '8004': { name: aiDescription.GLOBAL_INDIVIDUAL_ASSET_IDENTIFIER, maxLength: 30 },
    '8005': { name: aiDescription.PRICE_PER_UNIT_OF_MEASURE, length: 6 },
    '8006': { name: aiDescription.IDENTIFICATION_OF_THE_COMPONENTS_OF_AN_ITEM, length: 18 },
    '8007': { name: aiDescription.INTERNATIONAL_BANK_ACCOUNT_NUMBER, maxLength: 30 },
    '8008': { name: aiDescription.DATE_TIME_OF_PRODUCTION, minLength: 8, maxLength: 12 },
    '8018': { name: aiDescription.GLOBAL_SERVICE_RELATION_NUMBER, length: 18 },
    '8020': { name: aiDescription.PAYMENT_SLIP_REFERENCE_NUMBER, maxLength: 25 },
    '8100': { name: aiDescription.COUPON_EXTENDED_CODE_NUMBER_SYSTEM_AND_OFFER, length: 6 },
    '8101': { name: aiDescription.COUPON_EXTENDED_CODE_NUMBER_SYSTEM_OFFER_AND_END_OF_OFFER, length: 10 },
    '8102': { name: aiDescription.COUPON_EXTENDED_CODE_NUMBER_SYSTEM_PRECEDED_BY_0, length: 2 },
    '8110': { name: aiDescription.COUPON_CODE_ID_NORTH_AMERICA, maxLength: 30 },
    '90': { name: aiDescription.MUTUALLY_AGREED_VARIABLE_BETWEEN_TRADING_PARTNERS, maxLength: 30 },
    '91': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '92': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '93': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '94': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '95': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '96': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '97': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '98': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
    '99': { name: aiDescription.INTERNAL_COMPANY_CODE, maxLength: 30 },
}


const decode = str => {
    str = str.replace(/\W^\-/g, ' ').trim();

    let obj = {};
    while (str) {
        str = str.trim();
        let aiCode = '';
        let aiCodeLength = 0;
        let ai = null;
        let dataWithAiCode = '';
        let data = '';

        while (!ai && aiCodeLength < str.length) {
            aiCodeLength++;
            aiCode = str.substring(0, aiCodeLength);
            ai = applicationIdentifiers[aiCode];
        }

        if (ai) {
            if (ai.length)
                dataWithAiCode = str.substring(0, (ai.length + aiCodeLength));
            else if (ai.maxLength) {
                let strIndex = 0;

                while (strIndex < str.length && /\w|\-/.test(str[strIndex]))
                    strIndex++;

                dataWithAiCode = str.substring(0, strIndex + 1);
                if (ai.n || ai.y) {
                }

                if (ai.minLength && (strIndex + 1) < ai.minLength)
                    throw `Data "${dataWithAiCode.substring((aiCodeLength - 1), (strIndex - aiCodeLength))}" too short for Application Identifier ${ai.name};`

                else if (ai.maxLength < (strIndex + 1))
                    throw `Data "${dataWithAiCode.substring(aiCodeLength - 1, strIndex - aiCodeLength)}" too long for Application Identifier ${ai.name};`

            }
        }
        else
            throw new Error('Uncaught Error in Data Matrix Decode function');


        data = dataWithAiCode.substring(aiCodeLength, dataWithAiCode.length);
        data = /\w/.test(data[data.length - 1]) ? data : data.substring(0, data.length - 1);
        str = str.split(dataWithAiCode)[1];

        obj[ai.name] = data;
    }
    return obj;
}

const encode = () => 'Not yet implemented';

const dataMatrixCodec = { encode, decode, ...aiDescription }

export default dataMatrixCodec;